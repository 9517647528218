<template>
  <Transition name="slidein">
    <div v-if="props.show" class="absolute left-0 right-0 top-[100%]" :class="{ 'cursor-pointer' : !props.buttonText }">
      <div class="flex flex-wrap items-center justify-between gap-4 p-3 text-neutral-50" :class="props.type" @click="hide">
        <div class="flex-1 min-w-[200px] font-bold text-xl text-center">
          <AtomsImagesIcon :icon="props.icon" size="lg" class="mr-4 align-middle" v-if="props.icon" />
          <span v-html="props.text"></span>
        </div>
        <MoleculesButtonsButton class="ml-auto" v-if="props.buttonText" arrow-right="true">{{ props.buttonText }}</MoleculesButtonsButton>
        <div class="flex gap-2 ml-auto" v-if="props.confirm">
          <MoleculesButtonsButton @click="result(true)">{{ $t('Ano') }}</MoleculesButtonsButton>
          <MoleculesButtonsButton @click="result(false)">{{ $t('Ne') }}</MoleculesButtonsButton>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script setup>

const props = defineProps({
  show: Boolean,
  icon: String,
  text: String,
  buttonText: String,
  type: String,
  confirm: Boolean
});

const message = useMessage();

const emit = defineEmits(['hide', 'result']);

const hide = () => emit('hide');

const result = value => emit('result', value);

</script>